export const API_KEY = "";
//export const APPID = "wxfc4a4880a987937b"; //测试浣熊   生活CS
 export const APPID = "wxfdbf668f394f2b9c"; //正式浣熊  浣熊HX

// //测试https:
//export const REDIRECT_URI = 'https://raccoon.youxiangltd.com/#/home'
//export const REDIRECT_URI_Login = 'https://raccoon.youxiangltd.com/#/invitelogin'

// 正式
 export const REDIRECT_URI = "https://hxgzh.dezj.net/#/home";
 export const REDIREsCT_URI_Login = "https://hxgzh.dezj.net/#/invitelogin";


export const SCOPE = "snsapi_userinfo";
export const CUSTOM_VERSION = "1.7.7";

// 极光im appkey
export const IMappkey = "b077e9d8138d2dab9106abfb"; // 乘客端IMappkey
export const driverAppkey = "3316613763a70c159cb26fba"; //司机端Imappkey

// natapp -authtoken=a8973b6fe85c7847

